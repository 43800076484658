.Service_body {
    height: 100%;
    width: 100%;
    background-color: #243e95;
    display: flex;
    flex-direction: column;
}

.Service_body-Container {
    padding: 50px 0;
    background-color: #4359cd;
    margin: 50px 100px;
}

.Service_body-Container-title {
    text-align: center;
    color: white;
}

.Service_body-Container-connent {
    color: white;
    min-height: 500px;
    padding: 20px 50px;
}

@media screen and (max-width: 500px) {
    .Service_body-Container {
        padding: 50px 0;
        background-color: #0F1515;
        margin: 50px 0px;
    }

    .Service_body-Container-connent {
        color: white;
        padding: 20px 20px;
    }
    
}