a {
    text-decoration: none;
    color: white;
}
/* chung */
.title-h4-b {
    color: #000;
    text-align: center;
    font-size: 32px;
    font-weight: 500;
}

.title-h4-w {
    color: white;
    text-align: center;
    font-size: 32px;
    font-weight: 500;
}

/* header */
.Home-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #283890;
    width: 100%;
}

.Home-left {
    display: flex;
    color: white;
}

.Home-logo {
    margin-left: 20px;
    width: 150px;
}

.Home-header-text {
    margin-right: 20px;
    padding: 20px 0;
}

.Home-header-text:hover {
    color: #00d2ee;
}

/* Top */
.Home-top {
    display: flex;
}

.Home-top-left {
    width: 50%;
    background-color: #0F3799;
    position: relative;
}

.Home-top-text {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.Home-top-right {
    width: 50%;
    background-color: white;
    max-height: 1000px;
    min-height: 800px;
}

/* body 1 */
.Home-body-1-title {
    padding-top: 200px;
}

.Home-body-1-text {
    text-align: center;
    padding-top: 15px;
}

.Home-body-1-Container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
    align-content: center;
}

.Home-body-1-Card {
    margin: 30px 50px;
    display: flex;
    align-items: center;
    border: 2px solid #000;
    padding: 20px;
    width: 250px;
}

.Home-body-1-Card-img {
    width: 32px;
}

.Home-body-1-Card-title {
    margin-left: 15px;
}

.Home-body-1-Card:hover {
    background-color: #085fff;
    color: white;
    transition: transform .2s;
    transform: scale(1.1);
    border: 0px solid #000;
}

/* body 2 */
.Home-body-2 {
    margin-top: 200px;
    background-color: #243E95;
}

.Home-body-2-Container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 50px;
}

.Home-body-2-title {
    padding-top: 50px;
}

.Home-body-2-Card {
    display: flex;
    background-color: #4359CD;
    box-shadow: 2px 2px 2px 3px rgba(0, 0, 0, 0.2);
    margin: 50px 50px;
}

.Home-body-2-Card-img {
    width: 250px;
    height: 300px;
}

.Home-body-2-Card-inf {
    width: 100%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 350px;
}

.Home-body-2-Card:hover {
    transition: transform .2s;
    transform: scale(1.1);
}

/* body 3 */
.Home-body-3-title {
    margin-top: 200px;
}

.Home-body-3-Container {
    display: flex;
    padding: 50px;
}

.Home-body-3-img {
    width: 50%;
}

.Home-body-3-right {
    width: 50%;
    text-align: center;
    border: 1px solid #000;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 2px 2px 2px 3px rgba(0, 0, 0, 0.2);
}

.Home-body-3-btn {
    background-color: #4359CD;
    border: none;
    color: white;
    padding: 10px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
}

.Home-body-3-font-textarea {
    min-width: 600px;
}

/* body 4 */
.Home-body-4 {
    margin-top: 200px;
    background-color: #243E95;
}

.Home-body-4-title {
    padding-top: 50px;
    padding-bottom: 100px;
}

.Home-body-4-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.Home-body-4-card {
    background-color: #4359CD;
    display: flex;
    align-items: center;
    width: 800px;
    min-height: 150px;
    border-radius: 15px;
    padding: 20px;
    color: white;
    margin: 30px 50px;
}

.Home-body-4-card:hover {
    transition: transform .2s;
    transform: scale(1.1);
}

.Home-body-4-card-inf {
    width: 100%;
    margin-left: 30px;
}

.Home-body-4-card-img {
    width: 50px;
}

.Home-body-4-card-title {
    padding: 10px 0;
    font-weight: 700;
    text-align: center;
    font-size: 23px;
}

/* body 5 */
.Home-body-5-title {
    margin-top: 150px;
}

.Home-body-5-text {
    text-align: center;
    padding-top: 15px;
}

/*bottom*/
.Home_bottom-1 {
    /* margin-top: 100px; */
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: #124B9C;
    width: 100%;
    display: flex;
    justify-content: space-around;
    color: white;
}

.Home_bottom-1-logo {
    width: 300px;
}

.Home_bottom-1-info {
    min-width: 1000px;
}

.Home_bottom-1-info-1 {
    text-align: center;
}

.Home_bottom-1-info-img {
    width: 25px;
    margin-right: 5px;
}

.Home_bottom-1-info-2-3 {
    display: flex;
    justify-content: space-between;
}

.Home_bottom-1-info-5 {
    text-align: center;
}

.Home_bottom-2 {
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    width: 100%;
    background-color: #283890;
    color: white;
}

.Home_bottom-2_text {
    padding: 5px;
    font-size: 20px;
    font-weight: 500;
}

/* blog */
.Home-Body-5 {
    background-color: #1B2835;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.Home-body__5-title {
    margin-top: 100px;
}

.Home-body__5-Title__chir-2 {
    margin-top: 5px;
    text-align: center;
    font-weight: 500;
}

.Home-body__5_AllCard {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Home-body__5_AllCard-btn {
    width: 70px;
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
}

.Home-body__5_AllCard_container {
    display: flex;
    overflow-x: auto;
    max-width: 1500px;
}

.Home-body__5_AllCard_container::-webkit-scrollbar {
    display: none;
}

.Home-Body-card-5 {
    background-color: #FFFFFF;
    box-shadow: 2px 2px 2px 3px rgba(0, 0, 0, 0.2);
    margin-top: 50px;
    margin-bottom: 50px;
    margin-right: 25px;
    margin-left: 25px;
    padding: 20px;
    border-radius: 15px;
}

.Home-Body-card-5_Img_CT {
    width: 300px;
    height: 200px;
}

.Home-Body-card-5_Title_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Home-Body-card-5_Title-1 {
    text-align: center;
    margin: 5px;
    font-weight: 700;
    font-size: 25px;
}

.Home-Body-card-5_Title-2 {
    margin-top: 10px;
    width: 295px;
    text-align: center;
}

.Home-Body-card-5_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    border: 2px solid #000;
    font-weight: 500;
    background-color: Transparent;
    background-repeat: no-repeat;
    padding: 5px;
    margin: 30px;
}

.Home-Body-card-5_btn_Icon {
    width: 25px;
    margin-left: 5px;
}

.Home-Body-card-5_btn:hover {
    background-color: #124B9C;
    color: white;
    border: 0px;
    transition: transform .2s;
    transform: scale(1.1)
}

/* body 6 */
.Home-body-6 {
    margin: 0 50px;
    border-radius: 10px;
    margin-top: 100px;
    background-color: #243E95;
    margin-bottom: 150px;
}

.Home-body-6-title {
    padding-top: 50px;
}

.Home-body-6-container {
    padding: 50px;
}

@media screen and (max-width: 1200px) {

    /*day web*/
    .Home_bottom-1 {
        padding-top: 50px;
        padding-bottom: 30px;
        background-color: #124B9C;
        width: 100%;
        flex-direction: column;
        display: flex;
        color: white;
    }

    .Home_bottom-1-logo {
        width: 200px;
        height: 150px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
    }

    .Home_bottom-1-info {
        padding-top: 50px;
        min-width: 0;
    }

    .Home_bottom-1-info-2-3 {
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .Home_bottom-1-info-4 {
        text-align: center;
    }

    .Home_bottom-1-info-5 {
        text-align: center;
    }

    /* body 5 */
    .Home-body__5_AllCard_container {
        display: flex;
        overflow-x: auto;
        max-width: 1000px;
    }
}

@media screen and (max-width: 1024px) {

    /* body 5 */
    .Home-body__5_AllCard_container {
        display: flex;
        overflow-x: auto;
        max-width: 800px;
    }

    /* body 3 */
    .Home-body-3-font-textarea {
        min-width: 400px;
    }
}

@media screen and (max-width: 770px) {

    /* body 5 */
    .Home-body__5_AllCard_container {
        display: flex;
        overflow-x: auto;
        max-width: 500px;
    }

    .Home-body__5_AllCard-btn {
        display: none;
    }

    /* body 3 */
    .Home-body-3-Container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 50px;
    }

    /* body 3 */
    .Home-body-3-font-textarea {
        min-width: 200px;
    }
}

@media screen and (max-width: 425px) {

    /* header */
    .Home-header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-color: #283890;
        width: 100%;
    }

    .Home-left {
        display: none;
    }

    /* top */
    .Home-top {
        display: flex;
        flex-direction: column-reverse;
    }

    .Home-top-left {
        width: 100%;
        background-color: #0F3799;
        position: relative;
        min-height: 500px;
    }
    
    .Home-top-text {
        color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    
    .Home-top-right {
        width: 100%;
        background-color: white;
        max-height: 1000px;
        min-height: 800px;
    }

    /* body 2 */
    .Home-body-2-Card {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #4359CD;
        box-shadow: 2px 2px 2px 3px rgba(0, 0, 0, 0.2);
        margin: 50px 50px;
    }

    .Home-body-2-Card-img {
        margin: 10px 0;
        width: 200px;
        height: 250px;
    }

    .Home-body-2-Card-inf h4 {
        font-size: 15px;
    }

    /* body 6 */
    .Home-body-6 {
        margin: 0 0px;
        border-radius: 10px;
        margin-top: 100px;
        background-color: #243E95;
        margin-bottom: 150px;
    }

    .Home-body-6-container {
        padding: 20px;
    }

    /* body 3 */
    .Home-body-3-Container {
        display: flex;
        padding: 0px;
    }

    .Home-body-3-right {
        width: 100%;
        text-align: center;
        border: 1px solid #000;
        padding: 0px;
        border-radius: 20px;
        box-shadow: 2px 2px 2px 3px rgba(0, 0, 0, 0.2);
    }

    .Home-body-3-font-textarea {
        min-width: 300px;
    }
}

@media screen and (max-width: 320px) {

    /* body 2 */
    .Home-body-2-Card {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #4359CD;
        box-shadow: 2px 2px 2px 3px rgba(0, 0, 0, 0.2);
        margin: 50px 50px;
    }

    .Home-body-2-Card-img {
        margin: 10px 0;
        width: 200px;
        height: 250px;
    }

    .Home-body-2-Card-inf h4 {
        font-size: 15px;
    }

    /* body 3 */
    .Home-body-3-Container {
        display: flex;
        padding: 0px;
    }

    .Home-body-3-right {
        width: 100%;
        text-align: center;
        border: 1px solid #000;
        padding: 0px;
        border-radius: 20px;
        box-shadow: 2px 2px 2px 3px rgba(0, 0, 0, 0.2);
    }
}